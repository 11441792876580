import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { message, Spin, notification, Modal } from "antd";
import { fetchData } from "./lib/apis";
import { setCookie, AccountMetaData } from "./config/app";
import { formatPrice, formatK } from "./lib/helpers";

import { MainlayoutView, LoginView, AdminLayoutView } from "./views";

class AppComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }
  componentDidMount = () => { };

  notification = (options) => {
    let { type, title, content, key, duration } = options;
    notification[type]({
      message: title,
      description: content,
      key: key,
      duration: duration ? duration : 5,
    });
  };
  message = (options) => {
    let { type, key, content, duration } = options;
    message[type]({
      content: content,
      key: key,
      duration: duration
    });
  };
  getDetailUser = async () => {
    try {
      let response = await fetchData({
        url: "api/v1/user/detail",
        method: "get",
      });
      this.setState({
        user: response.data,
        setting: response.setting,
      });
    } catch (err) {
      this.notification({
        type: "error",
        title: "Signin",
        content: `${err.message || "Signin failed"
          } (3s redirect..)`,
      });
      setTimeout(() => {
        AccountMetaData.logOut();
      }, 3000);
    }
  };

  sleep = async (ms) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  };
  copyText = (text) => {
    let el = document.querySelector("#input-copy");
    el.textContent = text;
    el.select();
    document.execCommand("copy");
    this.message({
      title: "Copy text",
      content: "Copy Success",
      type: "success",
    });
  };

  render() {
    let prop_m = {
      notification: this.notification,
      message: this.message,
      user: this.state.user,
      getDetailUser: this.getDetailUser,
      fetchData: fetchData,
      AccountMetaData: AccountMetaData,
      formatPrice: formatPrice,
      sleep: this.sleep,
      copyText: this.copyText,
      formatK: formatK,
      getImageResize: (url, pixel) => {
        if(pixel){
          return url ? url+'?p='+pixel : "/no-photo.png";
        }
        return url;
      },
      logOut: () => AccountMetaData.logOut(),
      setting: this.state.setting
    };
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => <LoginView {...props} {...prop_m} />}
          />
          <Route
            path="/admin"
            render={(props) => <AdminLayoutView {...props} {...prop_m} />}
          />
          <Route
            path="/"
            render={(props) => <MainlayoutView {...props} {...prop_m} />}
          />
        </Switch>
      </Router>
    );
  }
}
export default AppComponent;
