import React from "react";
import { Popover, Tooltip } from 'antd';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }
    componentDidMount = () => {
       
    }
    render() {
        return (
            <header id="page-header" className="d-flex">
                <div className="container d-flex flex-column flex-md-row align-items-center p-3 px-md-4 justify-content-between">
                    <div className="d-flex flex-column flex-md-row align-items-center ">
                        <h5 className="my-0 mr-md-auto font-weight-normal"><img className="logo" src="/logo.png" /></h5>
                        <nav className="my-2 my-md-0 mr-md-3 mx-2">
                            {this.props.user.is_admin ? <a className="p-2 text-dark" href="/admin">Admin</a> : null}
                            <a className="p-2 text-dark" href={this.props.setting ? this.props.setting.link_support : "#"}>Support</a>
                            <a className="p-2 text-dark" href={this.props.setting ? this.props.setting.link_about : "#"}>About</a>
                        </nav>
                    </div>
                    <div className="d-flex align-items-center">
                        <a className="btn btn-outline-primary" href="#" style={{borderColor: "#0dbdbd", backgroundColor: "#0dbdbd", color: "#fff", marginRight: "10px"}}>{this.props.user.fullname}</a>
                        <Tooltip title="Sign Out">
                            <a style={{fontSize: "20px", color: "#e83f8b"}} href="#" onClick={() => this.props.logOut()}><i className="fal fa-sign-out-alt"></i></a>
                        </Tooltip>
                    </div>
                    </div>
            </header>
        )
    }
}
