import React, { Component } from 'react';
import { Result, Button } from 'antd';

export default class View404 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <main id="main-container">
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary" onClick={() => this.props.history.push("/")}>Back Home</Button>}
                />
            </main>
        )
    }
}