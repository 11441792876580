import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { fetchData } from '../lib/apis';
import { AccountMetaData, setCookie } from '../config/app';

import { GoogleLogin } from 'react-google-login';

import qs from 'qs';

export default class LoginView extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillMount = () => {
        if (this.props.AccountMetaData.checkIssetLogin()) {
            window.location.href = "/";
        }
        try{
            let hash = this.props.location.hash;
            let params = qs.parse(hash.replace('#', ''));
            if(params['id_token']) this.login(params)
        } catch(err){

        }
    }
    componentDidMount = () => {

    }
    login = async (values) => {
        try {
            this.setState({ loading: true })

            let resutl = await fetchData({
                url: 'api/v1/user/login',
                method: 'post',
                data: values
            });
            setCookie('auth', resutl['token'], 1);

            this.setState({ loading: false }, () => {
                // this.props.notification({
                //     type: 'success',
                //     title: 'Login',
                //     content: 'Login success'
                // })
                this.props.history.push('/');
            });

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({
                    type: 'error',
                    title: 'Login',
                    content: err.message || 'Signin failed'
                })
            });
        }
    };
    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    render() {
        return (
            <div id="page-login">
                <div className="row" style={{ height: "100%" }}>
                    <div className="col-md-4 d-flex align-items-center d-none d-sm-flex" style={{ backgroundColor: "#0DBDBD" }}>
                        <div className="block-content">
                            <div className="info-holder">
                                <h3>Welcome to ToolSpy !</h3>
                                <p className="info-title">The Free Ad Spy tool, Rate #1<br></br>
                                    ToolSpy lets you find Ads in seconds and inspire by featured creatives.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 d-flex align-items-center justify-content-center">
                        <Form id="form-login" className="d-flex flex-column align-items-center">
                            <h3 style={{ marginBottom: "16px" }}>Sign In To Continue</h3>
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    render={renderProps => (
                                        <Button onClick={renderProps.onClick} disabled={this.state.loading} id="btn-login" type="primary" className="btn btn-primary d-flex align-items-center" style={{ padding: "15px 30px", height: "45px", marginBottom: "16px" }}>
                                            <img src="/google.png" style={{ height: "30px", marginRight: "20px" }} />
                                            Sign in with Google
                                        </Button>
                                    )}
                                    onSuccess={(user) => console.log(user)}
                                    onFailure={(err) => console.log(err)}
                                    cookiePolicy={'single_host_origin'}
                                    uxMode={"redirect"}
                                    scope={"profile email"}
                                />
                            <div className="g-signin2" data-onsuccess={(user) => console.log(user)}></div>
                            <div className="text-center" style={{ maxWidth: "260px", color: "#999999", fontSize: "13px" }}>By clicking Sign In, you agree to our Terms and that you have read our Data Use Policy, including our Cookie Use.</div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}
