import React from 'react';

function LoadingScreen() {
  return (
    <div className="loading-screen">

    </div>
  )
}

export default LoadingScreen