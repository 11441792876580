import React from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount = () => {
        document.querySelectorAll('#sidebar .nav-main li').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                document.querySelector('#page-container').classList.remove('sidebar-o-xs');
            })
        })
        document.querySelectorAll('[data-toggle="sidebar"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                let action = this.getAttribute('data-action');
                if (action == 'sidebar_close') {
                    document.querySelector('#page-container').classList.remove('sidebar-o');
                    document.querySelector('#page-container').classList.remove('sidebar-o-xs');
                }
                if (action == 'sidebar_toggle') {
                    let page_container = document.querySelector('#page-container');
                    if (window.innerWidth > 992) {
                        if (page_container.classList.contains('sidebar-o')) {
                            page_container.classList.remove('sidebar-o');
                        } else {
                            page_container.classList.add('sidebar-o');
                        }
                    } else {
                        if (page_container.classList.contains('sidebar-o-xs')) {
                            page_container.classList.remove('sidebar-o-xs');
                        } else {
                            page_container.classList.add('sidebar-o-xs');
                        }
                    }

                }
            })
        })
        document.querySelectorAll('.nav-submenu[data-toggle="nav-submenu"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                let list_class = e.target.parentNode.getAttribute('class');
                if (list_class.indexOf('open') > -1) {
                    e.target.parentNode.classList.remove('open');
                } else {
                    e.target.parentNode.classList.add('open');
                }
            })
        })
    }
    render() {
        let pathname = window.location.pathname.replace(/\/$/g, '');
        let menu_admin = [
            {
                name: "Thống kê",
                link: "/admin",
                icon: 'far fa-home'
            },
            {
                name: "Quản lý"
            },
            {
                name: "Bài viết",
                link: "/admin/post",
                icon: "far fa-list"
            },
            {
                name: "Fanpage",
                link: "/admin/page",
                icon: "far fa-list"
            },
            {
                name: "Người dùng",
                link: "/admin/users",
                icon: "far fa-users"
            },
            {
                name: "Cài đặt"
            },
            {
                name: "Thiết lập",
                link: "/admin/setting",
                icon: "far fa-cog"
            }
        ]

        return (
            <nav id="sidebar">
                <div className="sidebar-content">
                    <div className="content-header content-header-fullrow px-15" style={{ borderBottom: "1px solid #eff2f5"}}>
                        <div className="content-header-section sidebar-mini-visible-b">
                            <span className="content-header-item font-w700 font-size-xl float-left animated fadeIn">
                                <span className="text-dual-primary-dark">c</span><span className="text-primary">b</span>
                            </span>
                        </div>
                        <div className="content-header-section text-center align-parent sidebar-mini-hidden">
                            <button type="button" className="btn btn-circle btn-dual-secondary d-lg-none align-v-r" data-toggle="sidebar" data-action="sidebar_close">
                                <i className="fa fa-times text-danger"></i>
                            </button>
                            <div className="content-header-item">
                                <a className="link-effect font-w700" href="/">
                                    <i className="si si-fire text-primary"></i>
                                    <span className="font-size-xl text-dual-primary-dark">Toolspy</span><span className="font-size-xl text-primary"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="content-side content-side-full">
                        <ul className="nav-main">
                            {
                                menu_admin.map((item, i) => {
                                    let menu = <li className="pt-10 nav-main-heading" key={i}>
                                        <span>{item.name}</span>
                                    </li>
                                    if (item.link) {
                                        menu = <li key={i}>
                                            <Link to={item.link} className={pathname == item.link ? "active" : ""}>
                                                <i className={item.icon}></i>
                                                <span>{item.name}</span>
                                            </Link>
                                        </li>
                                    }
                                    return menu
                                })
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
