import { Tooltip } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { LazyloadImg } from '../index';

export default class ItemPostWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.form = null;
    }

    render() {
        let type_atttachment = this.props.item.video ? "video" : "img";
        
        return (
            <div className="col col-sm-6 col-lg-3 col-xl-3 col-12 mb-4">
                <div className="post-item">
                    <div className="post-info">
                        <div className="card-img-top card-img-design" onClick={() => {
                            if(type_atttachment != "video"){
                                this.props.previewPost(this.props.index)
                            }
                        }}>
                            <LazyloadImg url={this.props.item.video || this.props.getImageResize(this.props.item.image, '180x540')} type={type_atttachment}/>
                            {/* <img src={this.props.getImageResize(this.props.item.image, '180x540')} onError={(e) => e.target.src = "/no-photo.png"} /> */}
                            <span className="views text-white" title="User views" ><i className="fa fa-eye"></i> {this.props.item.view}</span>
                            {
                                this.props.item.platform ?
                                    <span className="ads platform_icon">{this.props.item.platform}</span> : null
                            }
                        </div>
                        <div className="post-card-body">
                            <div className="d-flex justify-content-between page-link">
                                <div className="d-flex post-count">
                                    <div className="d-flex flex-column">
                                        <span className="post-increase text-success">{this.props.item.total_increase_reaction ? "+" + this.props.item.total_increase_reaction : "--"}</span>
                                        <span><i className="fal fa-thumbs-up"></i> {this.props.formatK(this.props.item.total_react)}</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="post-increase text-success">{this.props.item.total_increase_comment ? "+" + this.props.item.total_increase_comment : "--"}</span>
                                        <span><i className="fal fa-comment"></i> {this.props.formatK(this.props.item.total_comment)}</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="post-increase text-success">{this.props.item.total_increase_share ? "+" + this.props.item.total_increase_share : "--"}</span>
                                        <span><i className="fal fa-share"></i> {this.props.formatK(this.props.item.total_share)}</span>
                                    </div>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="post-tool d-flex justify-content-end">
                                        <Tooltip title="View post" placement="top">
                                            <a href={this.props.item.post_link} target="_blank"><i className="fal fa-link"></i></a>
                                        </Tooltip>
                                        <Tooltip title={this.props.item.video ? "Download video" : "Download photo"} placement="top">
                                            <a href={this.props.item.video || this.props.item.image} target="_blank"><i className="fal fa-download"></i></a>
                                        </Tooltip>
                                        <Tooltip title="View" placement="top">
                                            <a href="#"onClick={(e) => {
                                                e.preventDefault();
                                                this.props.previewPost(this.props.index)
                                            }} ><i className="fal fa-eye"></i></a>
                                        </Tooltip>
                                    </div>
                                    <span className="post-time">{moment(this.props.item.created_time).fromNow()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="post-author">
                        <div className="d-flex mb-2 mt-1 justify-content-between">
                            <h3 className="author_name">
                                <a href={this.props.item.from.link} target="_blank">{this.props.item.from.name || "Không xác định"}</a>
                            </h3>
                            <div className="d-flex justify-content-end post-tool">
                                <Tooltip title="Copy page url">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.copyText(this.props.item.from.link);
                                    }}><i className="fal fa-copy"></i></a>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="d-flex mb-2 mt-1 post-view-store">
                            <div><b>Store:</b></div>
                            <div className="text-info">
                                {
                                    this.props.item.store ? 
                                    <a href={this.props.item.store} target="_blank">{this.props.item.store}</a> : '--'
                                }
                            </div>
                        </div>
                        {
                            this.props.item.ga && this.props.item.ga.length > 0 ?
                                <div className="d-flex mb-2 mt-1 post-view-store">
                                    <div><b>Ga:</b></div>
                                    <div className="text-info">
                                        {
                                            this.props.item.ga.map((ga, i) => {
                                                return <a href="#" key={i} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.setFilter({ pixel: ga })
                                                }}>{ga}</a>;
                                            })
                                        }
                                    </div>
                                </div> : null
                        }
                        {
                            this.props.item.pixel && this.props.item.pixel.length > 0 ?
                                <div className="d-flex mb-2 mt-1 post-view-store post-view-ga">
                                    <div><b>Pixel:</b></div>
                                    <div className="text-info">
                                        {
                                            this.props.item.pixel.map((pixel, i) => {
                                                return <a href="#" key={i} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.setFilter({ pixel: pixel })
                                                }}>{this.props.item.pixel.length > 1 ? pixel.slice(0, 7) + '...' : pixel}</a>;
                                            })
                                        }
                                    </div>
                                </div> : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}
