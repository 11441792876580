import React from "react";
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, Table, Alert, Tag, DatePicker} from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';

export default class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            detailBanking: null
        };
        this.limit = 20;
        this.page = 1;
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await this.props.fetchData({
                url: `api/v1/ow/page/list?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">All: {this.state.total}</h3>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: "",
                            }}
                            ref={(evt) => this.formFilter = evt}
                            onChange={(e) => this.props.handleFilterData({ type: 'form', input: e, getFunction: this.getListData })}
                        >
                            <div className="row">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="keyword">
                                        <Input placeHolder="Tìm kiếm" />
                                    </Form.Item>
                                </div>
                                <div className="col-auto pl-5 pr-5">
                                    <Button className="d-flex align-items-center justify-content-center" type="primary" size={"middle"} onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData('reset');
                                    }}>Xóa lọc</Button>
                                </div>
                                <div className="col-auto pl-5 pr-5">
                                    <Tooltip title="Refresh">
                                        <Button type="primary" onClick={() => this.getListData()}>
                                            <i className="far fa-refresh"></i>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "#",
                                        index: "#",
                                        render: (item, value, index) => {
                                            return (this.page * this.limit - this.limit) + index + 1
                                        }
                                    },
                                    {
                                        title: "Ảnh",
                                        render: (item) => {
                                            return <img style={{witdh: "60px", height: "60px"}} src={`http://graph.facebook.com/${item.page_id}/picture?type=square`}/>
                                        }
                                    },
                                    {
                                        title: "Id",
                                        dataIndex: "page_id"
                                    },
                                    {
                                        title: "Tên",
                                        dataIndex: "name"
                                    },
                                    {
                                        title: "Link",
                                        dataIndex: "link",
                                        render: (item) => {
                                            return <a href={item} target="_blank">{item}</a>
                                        }
                                    },
                                    {
                                        title: "Last time",
                                        render: (item) => {
                                            return item.last_time ? moment(item.last_time).fromNow() : null
                                        }
                                    }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: true,
                                    onShowSizeChange: (current, size) => {
                                        this.page = current;
                                        this.limit = size;
                                        this.getListData();
                                    },
                                    pageSizeOptions: [20, 50, 100, 200, 500]
                                }}
                                rowKey="_id"
                                // rowSelection={{
                                //     type: "checkbox",
                                //     selectedRowKeys: this.state.dataSelected,
                                //     onChange: (values) => {
                                //         this.setState({
                                //             dataSelected: values
                                //         })
                                //     }
                                // }}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
