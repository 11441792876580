import React, { Component } from 'react';
import { Modal, Button, Spin } from 'antd';
import { LazyloadImg } from './index';
import moment from 'moment';

export default class DetailPost extends Component {
    constructor(props) {
        super(props);
        this.setState({
            reload: false
        })
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible || nextProps.preview_index != this.props.preview_index) {
            setTimeout(() => {
                this.props.getDetail(nextProps.item._id);
            }, 100)
        }
    }
    componentDidMount = () => {
        if (document.querySelector('#block-myChart #myChart')) this.props.getDetail(this.props.item._id);
        window.addEventListener('keydown', this.keydown);
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.keydown);
    }

    keydown = (event) => {
        if(event.keyCode == 37){
            this.props.handlePreviewPost(-1);
        }
        if(event.keyCode == 39){
            this.props.handlePreviewPost(1);
        }
    }

    render() {
        return (
            <Modal
                title={"Preview post"}
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={[<div><p><span><strong>←</strong> previous shot</span><span><strong>→</strong> next shot</span><span><strong>Esc</strong> close</span></p></div>]}
                width={900}
                centered
                className="preview-post"
            >
                <div>
                    {
                        this.props.preview_index > 0 ?
                        <a className="btn-slider btn-prev" onClick={() => this.props.handlePreviewPost(-1)}><i className="fal fa-chevron-left"></i></a> : null
                    }
                    {
                        this.props.preview_index < this.props.listData.length -1 ? 
                        <a className="btn-slider btn-next" onClick={() => this.props.handlePreviewPost(1)}><i className="fal fa-chevron-right"></i></a> : null
                    }
                </div>
                {
                    this.props.item ?

                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="block-content block-content-info" style={{ paddingLeft: "0px", paddingTop: "0px" }}>
                                        <div className="block-content" style={{ paddingTop: "0px" }}>
                                            <div className="d-flex align-items-center block-page-info">
                                                <img key={this.props.item['post_id']} src={this.props.item['from']['avatar'] || `http://graph.facebook.com/${this.props.item['from']['id']}/picture?type=square` || "/user.jpg"} style={{ width: "45px", height: "45px", borderRadius: "50%", marginRight: "20px" }} onError={(e) => e.target.src = "/user.jpg"} />
                                                <div className="d-flex flex-column">
                                                    <span>{this.props.item['from']['name'] || "Không xác định"}</span>
                                                    <span style={{ color: "#999999", fontSize: "12px" }}>{moment(this.props.item['created_time']).fromNow()}</span>
                                                </div>
                                            </div>
                                            <div className="post-title">
                                                {this.props.item['title']}
                                            </div>
                                        </div>
                                        <div className="d-flex block-content-img" style={{ height: "350px", backgroundColor: "#eff2f5", padding: "5px" }}>
                                            {
                                                this.props.item.video ?
                                                <video controls key={this.props.item['post_id']} >
                                                    <source src={this.props.item.video} type="video/mp4" />
                                                </video>
                                                : <img key={this.props.item['post_id']} 
                                                    src={this.props.getImageResize(this.props.item['image'], '180x540')} 
                                                    onError={(e) => e.target.src = "/no-photo.png"}
                                                />
                                            }
                                        </div>
                                        <div className="d-flex">
                                            <a href={this.props.item.video || this.props.item['image']} className="btn btn-sm btn-dark text-light flex-fill rounded-0 btn-bg-default" target="_blank">{this.props.item.video ? "View video" : "View image"}</a>
                                            <a href={this.props.item['post_link']} className="btn btn-sm btn-dark text-light flex-fill rounded-0" target="_blank">View post</a>
                                            <a href={this.props.item['from']['link']} className="btn btn-sm btn-dark text-light flex-fill rounded-0" target="_blank">View page</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex flex-column justify-content-between d-none d-sm-flex">
                                    <Spin spinning={this.props.loading}>
                                        <div id="block-myChart" style={{ padding: "0px", paddingRight: "24px", paddingTop: "50PX" }} key={this.props.item['post_id']}>
                                            <canvas id="myChart" width="400" height="280"></canvas>
                                        </div>
                                    </Spin>
                                    <div className="d-flex justify-content-between post-count">
                                        <div className="d-flex flex-column">
                                            <span className="post-increase text-success">{this.props.item.total_increase_reaction ? "+" + this.props.item.total_increase_reaction : "--"}</span>
                                            <span className="d-flex align-items-center"><i className="fal fa-thumbs-up" style={{ marginRight: "5px" }}></i> {this.props.formatK(this.props.item.total_react)} reaction</span>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span className="post-increase text-success">{this.props.item.total_increase_comment ? "+" + this.props.item.total_increase_comment : "--"}</span>
                                            <span className="d-flex align-items-center"><i className="fal fa-comment" style={{ marginRight: "5px" }}></i> {this.props.formatK(this.props.item.total_comment)} comments</span>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span className="post-increase text-success">{this.props.item.total_increase_share ? "+" + this.props.item.total_increase_share : "--"}</span>
                                            <span className="d-flex align-items-center"><i className="fal fa-share" style={{ marginRight: "5px" }}></i> {this.props.formatK(this.props.item.total_share)} shares</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-content post-full-content" style={{ backgroundColor: "#eff2f5" }}>
                                <p>{this.props.item['content']}</p>
                            </div>
                        </div>

                        : null
                }
            </Modal>
        )
    }
}
