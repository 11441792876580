import React from "react";
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, Checkbox, InputNumber } from 'antd';

export default class SettingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: false
        };
    }
    componentDidMount = () => {

    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true });

            let resutl = await this.props.fetchData({
                url: 'api/v1/ow/setting/save',
                method: 'post',
                data: values
            });

            this.setState({ loadingForm: false });
            this.props.notification({
                content: "Thao tác thành công",
                title: 'Cấu hình',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập thiết lập',
                type: 'error'
            })
        }
    }

    render() {

        return (
            <div className="content">
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Thiết lập</h3>
                    </div>
                    <div className="block-content">
                        <Form
                            initialValues={this.props.setting ? this.props.setting : {}}
                            onFinish={this.save}
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Item name="link_support" label="Link admin hỗ trợ">
                                        <Input />
                                    </Form.Item>

                                    <Form.Item name="link_about" label="Link giới thiệu">
                                        <Input />
                                    </Form.Item>

                                    {/* <Form.Item>
                                        <i>Lưu ý: Mỗi dòng 1 tài khoản (stk|chủ tài khoản|chi nhánh)</i>
                                    </Form.Item> */}
                                </div>
                            </div>
                            <Form.Item>
                                <Button loading={this.state.loadingForm} htmlType="submit" type="primary">Lưu thiết lập</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
