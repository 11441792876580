import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { Header, LoadingScreen, ModalExpiredActive } from '../../component';

import {
    HomeView, View404
} from '../index';

import { Modal } from 'antd';

export default class MainlayoutView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false
        };
    }
    componentWillMount = async () => {
        if (!this.props.AccountMetaData.checkIssetLogin()) {
            this.props.AccountMetaData.logIn();
        } else{
            this.props.getDetailUser();
        }
    }
    componentDidMount = async () => {
        window.onscroll = function() {
            let mybutton = document.querySelector('#btn-top');
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                mybutton.style.display = "block";
              } else {
                mybutton.style.display = "none";
              }
        };
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        if (!this.props.user) return <LoadingScreen />;

        var props_m = {
            ...this.props,
            scrollTop: this.scrollTop
        }
        return (
            <div>
                <Helmet>
                    <title>Toolspy</title>
                </Helmet>

                <Header {...props_m} />
                
                <textarea id="input-copy" style={{ opacity: 0, position: 'absolute', zIndex: 0, width: "1px", height: "1px", top: "-100px" }} onKeyPress={(e) => { e.preventDefault(); return false }} readOnly />
                <button onClick={this.scrollTop}  id="btn-top" title="Go to top" className="shadow"><i className="fal fa-arrow-up"></i></button>

                <ModalExpiredActive {...props_m} />

                <main id="main-container">
                    <Switch>
                        <Route
                            exact
                            path='/'
                            render={props => <HomeView {...props_m} {...props} />}
                        />
                        <Route
                            path='*'
                            render={props => <View404 {...props_m} {...props} />}
                        />
                    </Switch>
                </main>
            </div>
        )
    }
}
