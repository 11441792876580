import { dimens, AccountMetaData } from  '../config/app';

export function fetchData(data = {}){
    let url = `${process.env.REACT_APP_API_URL}/${data['url']}`;
    let options = {
        method: data.method ? data.method : 'GET',
        headers: {
            'Authorization' : AccountMetaData.getToken()
        }
    };
    if(options.method.toLocaleLowerCase() != 'get'){
        options['body'] = JSON.stringify(data.data);
        options['headers']['Content-Type'] = "application/json";
    }
    if(data['headers']) {
        options['headers'] = {
            ...options['headers'],
            ...data['headers']
        }
    }
    
    if(data.formdata){
        options['body'] = data.data;
        delete options['headers']['Content-Type'];
    }

    return new Promise((resolve, reject) => {
        fetch(url, options)
        .then(response =>  {
            return response.json();
        })
        .then((response) => {
            if(response.code == 401 || response.code == 403) {
                AccountMetaData.logOut();
                return resolve(response);
            }
            if(response.code && response.code != 200 || response.status_code && response.status_code != 200) return reject(response);
            return resolve(response);
        }).catch((err) => {
            return reject({
                code: 500,
                err: err
            });
        })
    })
}