import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Select, Form, Input, InputNumber, Upload, Checkbox, Table, Tag, Alert, Steps, DatePicker, Empty } from 'antd';
import qs from 'qs';
import moment from 'moment';

import { ItemPostWidget, LazyloadImg, DetailPostComponent, VirtualScroller } from '../component';

import Chart from 'chart.js/auto';

import { Grid, AutoSizer, WindowScroller, List, InfiniteLoader } from "react-virtualized";

export default class HomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            preview_index: 0
        };
        this.limit = 20;
        this.page = 1;
        this.filter = null;

        if (this.props.location.search) {
            this.filter = qs.parse(this.props.location.search.replace('?', ''));
            try {
                this.defaultDateFilter = this.filter.date ? this.filter.date.split('-') : [];
                if (this.defaultDateFilter[0]) this.defaultDateFilter[0] = moment(this.defaultDateFilter[0]);
                if (this.defaultDateFilter[1]) this.defaultDateFilter[1] = moment(this.defaultDateFilter[1]);
                this.state.defaultDateFilter = this.defaultDateFilter;
            } catch (err) {
                this.defaultDateFilter = [];
                this.filter['date'] = "";
            }
        }

        this.types = [
            {
                name: "Apparel",
                value: 1
            },
            {
                name: "Home & Living",
                value: 2
            },
            {
                name: "Accessories",
                value: 5
            },
            {
                name: "Dropshipping",
                value: 7
            },
            {
                name: "Other",
                value: 999
            },
            {
                name: "Trending",
                value: "trending"
            },
            {
                name: "Ads",
                value: "ads"
            },
            {
                name: "Post Link",
                value: "link"
            }
        ]
        this.platforms = [
            {
                name: "Burgerprints",
                value: "burgerprints"
            },
            {
                name: "Gearbubble",
                value: "gearbubble"
            },
            {
                name: "Gearlaunch",
                value: "gearlaunch"
            },
            {
                name: "Marcazo",
                value: "marcazo"
            },
            {
                name: "Merchize",
                value: "merchize"
            },
            {
                name: "Merchking",
                value: "merchking"
            },
            {
                name: "Moteefe",
                value: "moteefe"
            },
            {
                name: "Shopbase",
                value: "shopbase"
            },
            {
                name: "Shopify",
                value: "shopify"
            },
            {
                name: "Shineon",
                value: "shineon"
            },
            {
                name: "Sunfrog",
                value: "sunfrog"
            },
            {
                name: "Teechip",
                value: "teechip"
            },
            {
                name: "Teehag",
                value: "teehag"
            },
            {
                name: "Teespring",
                value: "teespring"
            },
            {
                name: "Teezily",
                value: "teezily"
            },
            {
                name: "Viralstyle",
                value: "viralstyle"
            },
            {
                name: "Woocommerce",
                value: "woocommerce"
            }
        ]
    }
    componentWillMount = () => {
        window.addEventListener('scroll', this.loadMore);
    }
    componentDidMount = () => {
        if (this.props.user.is_active) {
            this.getListData();
        }
    }
    getListData = async () => {
        try {
            this.setState({ loading: true });
            let query = {
                limit: this.limit,
                page: this.page,
            }
            if (this.filter) {
                query = {
                    ...query,
                    ...this.filter
                }
            }
            let response = await this.props.fetchData({
                url: `api/v1/post/list?${qs.stringify(query)}`,
                method: 'get'
            });
            let data = this.page == 1 ? [] : this.state.listData;

            data = data.concat(response.data);

            this.loadmore = response.data.length > 0 ? true : false;

            this.setState({
                listData: data,
                loading: false,
                loadMore: false
            })
        } catch (err) {
            this.setState({ loading: false, loadMore: false })
        }
    }

    getDetail = async (post_id) => {
        try {
            this.setState({ loadingDetail: true });

            let response = await this.props.fetchData({
                url: `api/v1/post/count/${post_id}`,
                method: 'get'
            });

            this.showChart(response.data);

            this.setState({
                loadingDetail: false
            })
        } catch (err) {
            this.setState({ loadingDetail: false })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadMore);
    }
    loadMore = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= (docHeight - 100) && !this.state.loadMore && this.loadmore && !this.state.loading) {
            this.setState({
                loadMore: true
            }, () => {
                setTimeout(() => {
                    this.page += 1;
                    this.getListData();
                }, 50)
            })
        }
        // if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight && !this.state.loadMore && this.loadmore && !this.state.loading) {
        //     this.setState({
        //         loadMore: true
        //     }, () => {
        //         setTimeout(() => {
        //             this.page += 1;
        //             this.getListData();
        //         }, 50)
        //     })
        // }
    }

    filterData = () => {
        this.loadmore = true;
        this.page = 1;
        this.filter = this.formFilter.getFieldsValue();
        window.history.replaceState(null, null, "?" + qs.stringify(this.filter));
        this.getListData();
    }
    previewPost = (index) => {
        if (!this.props.user.is_active) return;
        this.setState({
            visiblePreview: true,
            preview_index: index
        })
    }
    handlePreviewPost = (index) => {
        let preview_index = this.state.preview_index;
        if (preview_index + index >= 0 && preview_index + index < this.state.listData.length) {
            this.setState({
                preview_index: preview_index + index
            })
        }
    }
    showChart = (data) => {
        var config = {
            type: 'line',
            data: {
                labels: data.map((item) => item.date),
                datasets: [{
                    label: 'Reaction',
                    data: data.map((item) => item.reacts),
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1
                },
                {
                    label: 'Comment',
                    data: data.map((item) => item.comments),
                    backgroundColor: ["rgba(232,62,140,0.2)"],
                    borderColor: ["rgba(232,62,140,1)"],
                    borderWidth: 1
                },
                {
                    label: 'Share',
                    data: data.map((item) => item.shares),
                    backgroundColor: ["rgba(99,216,200,0.2)"],
                    borderColor: ["rgba(99,216,200,1)"],
                    borderWidth: 1
                }
                ]
            },
            options: {
                legend: {
                    display: false //This will do the task
                },
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                // min: 0,
                scales: {
                    y: {
                        min: 0
                    }
                }
            }
        }
        if (!window.myLine) {
            var ctx = document.querySelector('#block-myChart #myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        } else {
            document.querySelector('#block-myChart #myChart').remove();
            document.getElementById('block-myChart').innerHTML = '<canvas id="myChart" width="400" height="300"></canvas>';
            var ctx = document.querySelector('#block-myChart #myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        }

    }
    setFilter = (values) => {
        this.formFilter.setFieldsValue(values);
        this.props.scrollTop();
        this.filterData();
    }
    render() {
        return (
            <div className="container">
                <DetailPostComponent {...this.props} visible={this.state.visiblePreview} hide={() => this.setState({ visiblePreview: false })}
                    listData={this.state.listData || []} preview_index={this.state.preview_index} showChart={this.showChart}
                    handlePreviewPost={this.handlePreviewPost} loading={this.state.loadingDetail} getDetail={this.getDetail}
                    item={this.state.listData ? this.state.listData[this.state.preview_index || 0] : null}
                />
                <div className="row">
                    <div className="col-12">
                        <div className="block block-rounded">
                            <div className="block-content block-filter">
                                <Form
                                    ref={(e) => this.formFilter = e}
                                    initialValues={{
                                        type: this.filter ? this.filter.type : "",
                                        platform: this.filter ? this.filter.platform : "",
                                        sort: this.filter && this.filter.sort ? this.filter.sort : "created_time",
                                        likes: this.filter ? this.filter.likes : "",
                                        comments: this.filter ? this.filter.comments : "",
                                        shares: this.filter ? this.filter.shares : "",
                                        date: this.filter && this.filter.date ? this.filter.date : "",
                                        pixel: this.filter ? this.filter.pixel : ""
                                    }}
                                    className="form-filter"
                                >
                                    <Form.Item noStyle name="time_search">
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item noStyle name="date">
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <div className="d-flex">
                                        <div className="d-flex label-form-filter">
                                            <Form.Item>Basic:</Form.Item>
                                        </div>
                                        <div className="row" style={{ flex: "auto" }}>
                                            <Form.Item name="sort" className="col-6 col-md-3">
                                                <Select>
                                                    <Select.Option value="likes">Likes</Select.Option>
                                                    <Select.Option value="comments">Comments</Select.Option>
                                                    <Select.Option value="shares">Shares</Select.Option>
                                                    <Select.Option value="trending">Trending</Select.Option>
                                                    <Select.Option value="created_time">Created time</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item className="col-md-3 col-6">
                                                <DatePicker.RangePicker
                                                    defaultValue={this.state.defaultDateFilter}
                                                    ranges={{
                                                        'This week': [moment().startOf("week").add(1, "day"), moment().endOf("week").add(1, "day")],
                                                        'This month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                                    }}
                                                    onChange={(moments, dateString) => {
                                                        this.setState({
                                                            defaultDateFilter: moments
                                                        })
                                                        this.formFilter.setFieldsValue({
                                                            date: dateString[0] && dateString[1] ? moment(dateString[0]).format('YYYY/MM/DD') + '-' + moment(dateString[1]).format('YYYY/MM/DD') : ""
                                                        })
                                                    }}
                                                    format="YYYY/MM/DD"
                                                    allowClear={[false, false]}
                                                    style={{ width: "100%" }}
                                                    value={this.state.defaultDateFilter}
                                                />
                                            </Form.Item>
                                            <Form.Item name="type" className="col-6 col-md-3">
                                                <Select>
                                                    <Select.Option value="">All data</Select.Option>
                                                    {
                                                        this.types.map((item, i) => {
                                                            return <Select.Option key={i} value={item.value.toString()}>{item.name}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="platform" className="col-6 col-md-3">
                                                <Select>
                                                    <Select.Option value="">All platforms</Select.Option>
                                                    {
                                                        this.platforms.map((item, i) => {
                                                            return <Select.Option key={i} value={item.value}>{item.name}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex label-form-filter">
                                            <Form.Item>Advanced:</Form.Item>
                                        </div>
                                        <div className="row flex-fill">
                                            <Form.Item name="likes" className="col-6 col-md-3">
                                                <Input placeholder="0-1000" addonAfter={<i className="far fa-thumbs-up"></i>} />
                                            </Form.Item>
                                            <Form.Item name="comments" className="col-6 col-md-3">
                                                <Input placeholder="0-1000" addonAfter={<i className="far fa-comment"></i>} />
                                            </Form.Item>
                                            <Form.Item name="shares" className="col-6 col-md-3">
                                                <Input placeholder="0-1000" addonAfter={<i className="far fa-share"></i>} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex label-form-filter">
                                            <Form.Item></Form.Item>
                                        </div>
                                        <div className="row flex-fill">
                                            <Form.Item name="pixel" className="col-6 col-md-3">
                                                <Input placeholder="Enter ga or FB pixel or domain" />
                                            </Form.Item>
                                            <Form.Item className="col-md-3">
                                                <div className="d-flex align-items-center">
                                                    <Button htmlType="button" type="primary" className="d-flex align-items-center btn-bg-default" onClick={this.filterData} disabled={this.state.loading}>Search <i className="far fa-search" style={{ marginLeft: "5px" }}></i></Button>
                                                    <Button style={{ marginLeft: "10px", background: "#e83e8c", borderColor: "#e83e8c" }} htmlType="button" type="danger" className="d-flex align-items-center" onClick={() => {
                                                        this.formFilter.setFieldsValue({
                                                            date: "",
                                                            type: "",
                                                            comments: "",
                                                            likes: "",
                                                            shares: "",
                                                            sort: "created_time",
                                                            platform: "",
                                                            pixel: ""
                                                        });
                                                        this.setState({
                                                            defaultDateFilter: []
                                                        })

                                                        this.filterData();
                                                    }}>Clear</Button>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="post-list">
                    {
                        !this.props.user.is_active ? 
                            <div className="row">
                                {
                                    [1,2,3,4,5,6,7,8,9,10,11,12].map((i) => {
                                        let item = { "_id": i, "post_id": i, "cat_id": i, "content": null, "created_time": Date.now(), "from": { "id": i, "name": "Amazing Tees Gift" } };
                                        return <ItemPostWidget key={item.post_id} item={item} {...this.props} previewPost={this.previewPost} index={i} setFilter={this.setFilter} />
                                    })
                                }
                            </div>
                        : <Spin spinning={this.state.loading}>
                            <div className="row">
                                {/* <WindowScroller>
                                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                                    <List
                                        autoHeight
                                        height={height}
                                        isScrolling={isScrolling}
                                        onScroll={onChildScroll}
                                        scrollTop={scrollTop}
                                        rowRenderer={({ key, rowIndex, index}) => {
                                            console.log(key, rowIndex)
                                            return <div className="col-md-6" style={{height:"460px"}}>sss</div>
                                        }}
                                        rowCount={10}
                                        rowHeight={460}
                                        width={100}
                                        style={{width: "100%"}}
                                    />
                                )}
                            </WindowScroller> */}
                                {
                                    this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                        return <ItemPostWidget key={item.post_id} item={item} {...this.props} previewPost={this.previewPost} index={i} setFilter={this.setFilter} />
                                    }) : !this.state.loading ?
                                        <div className="col-md-12">
                                            <Empty />
                                        </div>
                                        : null
                                }

                            </div>
                        </Spin>
                    }
                    {
                        this.state.loadMore ?
                            <Button block className="btn-loading">Loading data..</Button> : null
                    }
                </div>
            </div>
        );
    }
}
