import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { AdminHeader, AdminSidebar, LoadingScreen } from '../../component';
import {
    View404, AdminUserView, AdminStatisticalView, AdminSettingView, AdminPostView, AdminPageView
} from '../index';

import '../../css/admin.css';

export default class AdminLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        if(!document.querySelector('link.theme-admin')){
            let node = document.createElement("link");
            node.setAttribute("href", "/css/codebase.css");
            node.setAttribute("rel", "stylesheet");
            node.classList.add("theme-admin");

            document.querySelector('head').append(node);
        }
    }
    componentWillMount = async () => {
        if (!this.props.AccountMetaData.checkIssetLogin()) {
            this.props.AccountMetaData.logIn();
        } else{
            this.props.getDetailUser();
        }
    }

    handleFilterData = ({ type, input, getFunction}) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                getFunction({ type: 'reset' });
            }, 300);
        } else {
            getFunction({ type: 'reset' });
        }
    }

    render() {

        if (!this.props.user) return <LoadingScreen />;

        if(!this.props.user.is_admin){
            this.props.AccountMetaData.logOut();
            return null;
        }

        var props_m = {
            ...this.props,
            handleFilterData: this.handleFilterData
        }
        return (
            <div id="page-container" className="page-admin sidebar-o enable-page-overlay side-scroll page-header-fixed main-content-narrow side-trans-enabled">
                <Helmet>
                    <title>Admin toolspy</title>
                </Helmet>

                <AdminHeader {...props_m} />
                <AdminSidebar {...props_m} />
              
                <main id="main-container">
                    <Switch>
                        <Route
                            exact
                            path='/admin'
                            render={props => <AdminStatisticalView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/users'
                            render={props => <AdminUserView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/setting'
                            render={props => <AdminSettingView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/post'
                            render={props => <AdminPostView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/page'
                            render={props => <AdminPageView {...props_m} {...props} />}
                        />

                        <Route
                            path="*"
                            render={props => <View404 {...props} />}
                        />
                    </Switch>
                </main>
            </div>
        )
    }
}
