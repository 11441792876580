import React from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount = () => {
        document.querySelectorAll('#sidebar .nav-main li').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                document.querySelector('#page-container').classList.remove('sidebar-o-xs');
            })
        })
        document.querySelectorAll('[data-toggle="sidebar"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                let action = this.getAttribute('data-action');
                if (action == 'sidebar_close') {
                    document.querySelector('#page-container').classList.remove('sidebar-o');
                    document.querySelector('#page-container').classList.remove('sidebar-o-xs');
                }
                if (action == 'sidebar_toggle') {
                    let page_container = document.querySelector('#page-container');
                    if (window.innerWidth > 992) {
                        if (page_container.classList.contains('sidebar-o')) {
                            page_container.classList.remove('sidebar-o');
                        } else {
                            page_container.classList.add('sidebar-o');
                        }
                    } else {
                        if (page_container.classList.contains('sidebar-o-xs')) {
                            page_container.classList.remove('sidebar-o-xs');
                        } else {
                            page_container.classList.add('sidebar-o-xs');
                        }
                    }

                }
            })
        })
        document.querySelectorAll('.nav-submenu[data-toggle="nav-submenu"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                let list_class = e.target.parentNode.getAttribute('class');
                if (list_class.indexOf('open') > -1) {
                    e.target.parentNode.classList.remove('open');
                } else {
                    e.target.parentNode.classList.add('open');
                }
            })
        })
    }
    render() {
        let pathname = window.location.pathname.replace(/\/$/g, '');
        let menu_admin = [
            {
                name: "Thống kê",
                link: "/admin",
                icon: 'far fa-home'
            },
            {
                name: "Quản lý"
            },
            {
                name: "Giao dịch",
                link: "/admin/transaction",
                icon: 'far fa-money'
            },
            {
                name: "Thiết bị",
                link: "/admin/device",
                icon: "far fa-desktop"
            },
            {
                name: "Người dùng",
                link: "/admin/users",
                icon: "far fa-users"
            },
            {
                name: "Cài đặt"
            },
            {
                name: "Thiết lập",
                link: "/admin/setting",
                icon: "far fa-cog"
            }
        ]

        return (
            <nav id="sidebar">
                <div className="sidebar-content">
                    <div className="content-header content-header-fullrow px-15">
                        <div className="content-header-section sidebar-mini-visible-b">
                            <span className="content-header-item font-w700 font-size-xl float-left animated fadeIn">
                                <span className="text-dual-primary-dark">c</span><span className="text-primary">b</span>
                            </span>
                        </div>
                        <div className="content-header-section text-center align-parent sidebar-mini-hidden">
                            <button type="button" className="btn btn-circle btn-dual-secondary d-lg-none align-v-r" data-toggle="sidebar" data-action="sidebar_close">
                                <i className="fa fa-times text-danger"></i>
                            </button>
                            <div className="content-header-item">
                                <a className="link-effect font-w700" href="/">
                                    <i className="si si-fire text-primary"></i>
                                    <span className="font-size-xl text-dual-primary-dark">SMS</span><span className="font-size-xl text-primary"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    {
                        pathname.indexOf('/admin') == -1 ?
                        <div className="content-side content-side-full content-side-user px-10 align-parent">
                            <div className="sidebar-mini-visible-b align-v animated fadeIn">
                                <img className="img-avatar img-avatar32" src="/user.jpg" alt="" />
                            </div>
                            <div className="sidebar-mini-hidden-b text-center">
                                <a className="img-link" href="be_pages_generic_profile.html">
                                    <img className="img-avatar" src="/user.jpg" alt="" />
                                </a>
                                <ul className="list-inline mt-10">
                                    <li className="list-inline-item">
                                        <a className="link-effect text-dual-primary-dark font-size-sm font-w600 text-uppercase" href="#">{this.props.formatPrice(this.props.user.balance)}</a>
                                    </li>
                                </ul>
                            </div>
                        </div> : null
                    }
                    <div className="content-side content-side-full">
                        {
                            pathname.indexOf('/admin') == 0 ?
                                <ul className="nav-main">
                                    {
                                        menu_admin.map((item) => {
                                            let menu = <li className="pt-10 nav-main-heading">
                                                <span>{item.name}</span>
                                            </li>
                                            if(item.link){
                                                menu = <li>
                                                    <Link to={item.link} className={pathname == item.link ? "active" : ""}>
                                                        <i className={item.icon}></i>
                                                        <span>{item.name}</span>
                                                    </Link>
                                                </li>
                                            }
                                            return menu
                                        })
                                    }
                                </ul>
                            : 
                            <ul className="nav-main">
                                <li className="pt-10 nav-main-heading">
                                    <span>Service</span>
                                </li>
                                <li>
                                    <Link to="/statistical" className={pathname == '/statistical' ? "active" : ""}>
                                        <i className="far fa-chart-bar"></i>
                                        <span>Statistical</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/task" className={pathname == '/task' ? "active" : ""}>
                                        <i className="far fa-tasks"></i>
                                        <span>Task</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" className={pathname == '' ? "active" : ""}>
                                        <i className="far fa-envelope"></i>
                                        <span>Messages</span>
                                    </Link>
                                </li>
                                <li className="pt-10 nav-main-heading">
                                    <span>Financial</span>
                                </li>
                                <li>
                                    <Link to="/payment/history" className={pathname == '/payment/history' ? "active" : ""}>
                                        <i className="far fa-usd-circle"></i>
                                        <span>Payment History</span>
                                    </Link>
                                </li>
                                <li className="pt-10 nav-main-heading">
                                    <span>Setting</span>
                                </li>
                                <li>
                                    <Link to="/me" className={pathname == '/me' ? "active" : ""}>
                                        <i className="far fa-user"></i>
                                        <span>Account</span>
                                    </Link>
                                </li>
                                <li>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.showVisibleChangePassword();
                                    }}>
                                        <i className="far fa-lock"></i>
                                        <span>Reset Password</span>
                                    </a>
                                </li>
                                {
                                    this.props.user.is_admin ?
                                    <li>
                                        <Link to="/admin" className={pathname == '/admin' ? "active" : ""}>
                                            <i className="far fa-user-crown"></i>
                                            <span>Admin</span>
                                        </Link>
                                    </li> : null
                                }
                                <li>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.AccountMetaData.logOut()
                                    }}>
                                        <i className="far fa-sign-out"></i>
                                        <span>Logout</span>
                                    </a>
                                </li>
                                <li className="pt-10 nav-main-heading">
                                    <span>support & help </span>
                                </li>
                                <li>
                                    <a href={this.props.service.link_support || "#"} target="_blank">
                                        <i className="far fa-file-alt"></i>
                                        <span>Document</span>
                                    </a>
                                </li>
                            </ul>
                        }
                    </div>
                </div>
            </nav>
        );
    }
}
