import React from "react";
import moment from 'moment';
import { Modal, Pagination, Button, Form, Input, Tooltip, Select, Spin, Table, Tag, Switch } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';
import { LazyloadImg } from '../../component';

export default class AdminPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            dataSelected: []
        };
        this.limit = 20;
        this.page = 1;
        this.cate_filter = [
            {
                name: "Apparel",
                value: 1
            },
            {
                name: "Home & Living",
                value: 2
            },
            {
                name: "Accessories",
                value: 5
            },
            {
                name: "Dropshipping",
                value: 7
            }
        ]
    }
    componentWillMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            this.setState({ loading: true })
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await this.props.fetchData({
                url: `api/v1/ow/post/list?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total || 0,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }
    update = async (ids, data) => {
        let _this = this;
        if (ids.length == 0) {
            _this.props.notification({
                content: "Chọn ít nhất 1 đối tượng",
                title: 'Cập nhập',
                type: 'warning'
            })
            return
        }
        Modal.confirm({
            title: 'Cập nhập',
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await _this.props.fetchData({
                            url: `api/v1/ow/post/update`,
                            method: 'post',
                            data: {
                                ids: ids,
                                ...data
                            }
                        });
                        _this.getListData();
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Cập nhập',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }

    deleteMany = async (ids) => {
        let _this = this;
        if (ids.length == 0) {
            _this.props.notification({
                content: "Chọn ít nhất 1 đối tượng",
                title: 'Xóa',
                type: 'warning'
            })
            return
        }
        Modal.confirm({
            title: 'Xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await _this.props.fetchData({
                            url: `api/v1/ow/post/delete`,
                            method: 'delete',
                            data: {
                                ids: ids
                            }
                        });
                        _this.getListData();
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Xóa',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }

    render() {
        return (
            <div className="content">
                <div className="block block-rounded">
                    <div className="block-header block-header-default">
                        <h3 className="block-title">All: {this.state.total}</h3>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <Tooltip title="Refresh">
                                    <Button className="mr-10" type="primary" onClick={() => this.getListData()}>
                                        <i className="far fa-refresh"></i>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button className="mr-10" type="danger" onClick={() => this.deleteMany(this.state.dataSelected)}>
                                        <i className="far fa-trash"></i>
                                    </Button>
                                </Tooltip>
                                <Button className="mr-10" type="danger" onClick={() => {
                                    this.update(this.state.dataSelected, { is_hidden: 1 })
                                }}>
                                    Ẩn
                                </Button>
                                <Button className="mr-10" type="danger" onClick={() => {
                                    this.update(this.state.dataSelected, { is_hidden: 0 })
                                }}>
                                    Hiển thị
                                </Button>
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                type: "",
                                is_hidden: "",
                                source: "",
                                status: "",
                                sort: "",
                                attachment_type: ""
                            }}
                            ref={(evt) => this.formFilter = evt}
                            onChange={(e) => this.props.handleFilterData({ type: 'form', input: e, getFunction: this.getListData })}
                        >
                            <div className="row">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="post_id">
                                        <Input placeholder="Post id" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="page_id">
                                        <Input placeholder="Page id" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="store">
                                        <Input placeholder="Store" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="ga">
                                        <Input placeholder="Ga" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="type">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">Danh mục</Select.Option>
                                            {
                                                this.cate_filter.map((item, i) => {
                                                    return <Select.Option value={item.value} key={i}>{item.name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="is_hidden">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={1}>Hiển thị</Select.Option>
                                            <Select.Option value={2}>Ẩn</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="source">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">Nguồn</Select.Option>
                                            <Select.Option value={1}>Facebook</Select.Option>
                                            <Select.Option value={0}>Spypro</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">TT Get</Select.Option>
                                            <Select.Option value={1}>Đã get</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="sort">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">Sắp xếp</Select.Option>
                                            <Select.Option value={1}>Ngày tạo <i className="far fa-sort-amount-up"></i></Select.Option>
                                            <Select.Option value={2}>Ngày cập nhập <i className="far fa-sort-amount-down"></i></Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="attachment_type">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">Phân Loại</Select.Option>
                                            <Select.Option value={1}>Img</Select.Option>
                                            <Select.Option value={2}>Video</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pr-5">
                                    <Form.Item>
                                        <Button className="d-flex align-items-center justify-content-center" type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getListData('reset');
                                        }}>Xóa lọc</Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    // {
                                    //     title: "#",
                                    //     index: "#",
                                    //     render: (item, value, index) => {
                                    //         return (this.page * this.limit - this.limit) + index + 1
                                    //     }
                                    // },
                                    {
                                        title: "File",
                                        className: "text-center",
                                        render: (item) => {
                                            return <a href={item.video || item.image} target="_blank">
                                                {
                                                    item.video ?
                                                        <i className="fa-2x far fa-film"></i>
                                                        : <LazyloadImg style={{ width: "80px", height: "80px" }} url={this.props.getImageResize(item.image, '80x80')} type={"img"} />
                                                }
                                            </a>
                                        }
                                    },
                                    {
                                        title: "Id",
                                        dataIndex: "post_id",
                                        render: (item) => {
                                            return <Tooltip title={item}>
                                                <div className="text-nowrap overflow-hidden" style={{ maxWidth: "160px", textOverflow: "ellipsis" }}><a target="_blank" href={`https://facebook.com/${item}`}>{item}</a></div>
                                            </Tooltip>
                                        }
                                    },
                                    {
                                        title: "Id page",
                                        render: (item) => {
                                            return item.from ? <a href={`https://facebook.com/${item.from.id}`} target="_blank">{item.from.id}</a> : ""
                                        }
                                    },
                                    {
                                        title: "Hiển thị",
                                        className: "text-nowrap text-center",
                                        render: (item) => {
                                            return <Switch checked={item.is_hidden !== 1} size="small" onChange={(e) => {
                                                this.update([item._id], {
                                                    is_hidden: e ? 0 : 1
                                                })
                                            }} />
                                        }
                                    },
                                    {
                                        title: "Tiêu đề",
                                        dataIndex: "title",
                                        render: (item) => {
                                            return <div>{item ? item.slice(0, 20) : ""}</div>
                                        }
                                    },
                                    // {
                                    //     title: "Nội dung",
                                    //     dataIndex: "content",
                                    //     render: (item) => {
                                    //         return <Input.TextArea value={item ? item.slice(0, 20) : ""} />
                                    //     }
                                    // },
                                    {
                                        title: "GA",
                                        dataIndex: "ga",
                                        render: (item) => {
                                            return <Input.TextArea value={item.join("\n")} />
                                        }
                                    },
                                    {
                                        title: "Store",
                                        dataIndex: "store",
                                        render: (item) => {
                                            return <Tooltip title={item}>
                                                <div className="text-nowrap overflow-hidden" style={{ maxWidth: "180px", textOverflow: "ellipsis" }}><a href={item} target="_blank">{item}</a></div>
                                            </Tooltip>
                                        }
                                    },
                                    {
                                        title: "Ngày tạo",
                                        render: (item) => {
                                            return <div className="text-nowrap">{item.created_time ? moment(item.created_time).format('HH:mm DD/MM/YYYY') : ""}</div>
                                        }
                                    },
                                    {
                                        title: "Cập nhập",
                                        render: (item) => {
                                            return <div className="text-nowrap">{item.updated_time ? moment(item.updated_time).format('HH:mm DD/MM/YYYY') : ""}</div>
                                        }
                                    },
                                    // {
                                    //     title: "",
                                    //     className: "text-right",
                                    //     render: (item) => {
                                    //         return <div>
                                    //             <Tooltip title="Duyệt">
                                    //                 <Button size="small" type="primary" onClick={() => {

                                    //                 }}><i className="far fa-edit"></i></Button>
                                    //             </Tooltip>
                                    //         </div>
                                    //     }
                                    // }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: true,
                                    onShowSizeChange: (current, size) => {
                                        this.page = current;
                                        this.limit = size;
                                        this.getListData();
                                    },
                                    pageSizeOptions: [20, 50, 100, 200, 500]
                                }}
                                rowKey="_id"
                                rowSelection={{
                                    type: "checkbox",
                                    selectedRowKeys: this.state.dataSelected,
                                    onChange: (values) => {
                                        this.setState({
                                            dataSelected: values
                                        })
                                    }
                                }}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
